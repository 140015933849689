<template>
  <div>
    <core-checkout :id="id"></core-checkout>
  </div>
</template>


<script>
import {mapMutations, mapGetters} from 'vuex'
import api from '@/plugins/api'
let cookie = require('vue-cookie');

export default {
  components: {  },
  computed: {
    ...mapGetters({
    })
  },
  data() {
    return {
      id: this.$route.params.id
    }
  },
  mounted() {
  },
  watch: {

  },
  methods: {

  }
}
</script>

<style>

</style>